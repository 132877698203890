// Add your providers here for easy indexing.
export { TranslateProvider } from "./translate/translate.service";
export { HotelProvider } from "./hotel/hotel.service";
export { WeatherService } from "./weather/weather.service";
export { CarsService } from "./cars/cars.service";
export { ActivitiesService } from "./activities/activities.service";
import { MenuOptInterface } from "../interfaces/menuOpt.interface";

export const OptionMenu: MenuOptInterface[] = [

    {
        "title": "Inicio",
        "url": "/club-fibex/home",
        "direct": "forward",
        "icon": "star",
        "show": true,
        "showHome": true,
        "showMenu": true, 
        "image": 'assets/icons/home.png',
        "img":  'assets/icons/home.png',
        // "image": ""
    },
    {
        "title": "Favoritos",
        "url": "/club-fibex/favorite",
        "direct": "forward",
        "icon": "share-social",
        "show": true,
        "showHome": true,
        "showMenu": true,
        "img":  'assets/icons/favorite.png',
        "image": 'assets/icons/favorite.png'
    },
    {
        "title": "Aliados",
        "url": "club-fibex/all-marks",
        "direct": "root",
        "icon": "",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/Iconos1.png',
        "img": "assets/icons/store.png",
        "image":"assets/icons/store.png",
    },
    // {
    //     "title": "Tarjeta Regalo",
    //     "url": "club-fibex/gift-card-home",
    //     "direct": "forward",
    //     "icon": "",
    //     "show": true,
    //     "showHome": true,
    //   "showMenu": true,
    //     // "image": 'assets/img/icons/Iconos2.png'
    //     "img": "assets/icons/gift_card.png",
    //     "image": "assets/icons/gift_card.png",
    // },
    {
        "title": "Promociones",
        "url": "club-fibex/product-list",
        "direct": "forward",
        "icon": "",
        "show": true,
        "showHome": true,
      "showMenu": true,
        // "image": 'assets/img/icons/Iconos2.png'
        "img": "assets/icons/promotions.png",
        "image": "assets/icons/promotions.png",
    },
    {
        "title": "Cupones",
        "url": "club-fibex/club-qr",
        "direct": "forward",
        "icon": "",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/Iconos3.png'
        "img": "assets/icons/coupon.png",
        "image": "assets/icons/coupon.png"
    },
    {
        "title": "Perfil", 
        "url": "club-fibex/edit-profile",
        "direct": "forward",
        "icon": "",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/iconPlan.png'
        "img": "assets/icons/profile_icon.png",
        "image": "assets/icons/profile_icon.png"
    }, 
    {
        "title": "Salir", 
        "url": "logout",
        "direct": "forward",
        "icon": "",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/iconPlan.png'
        "img": "assets/icons/logout.png",
        "image": "assets/icons/logout.png" 
    }, 
]

export const personalizedMenu: MenuOptInterface[] = [
    {
        "title": "Clave Wifi",
        "url": "/wifi",
        "direct": "forward",
        "icon": "star",
        "show": true,
        "showHome": true,
        "showMenu": true,
        // "image": 'assets/img/icons/Iconos1.png',
        "img": "assets/img/svg/iconos_azules/wifi-pass2.svg",
        "image": "assets/img/svg/iconos_azules/wifi-pass.svg"
    }
]

export const metodosPagoImages = [
    "assets/img/metodos-pago-images/Paypal_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/BitCoin_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/MasterCard_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/Mercantil_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/Ethereum_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/Visa_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/BNC_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/Zelle_Mesa de trabajo 1.png",
]

