import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ProductInterface } from '../../../../interfaces/product.interface';
import { Router } from '@angular/router';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { IonSlides, ModalController, NavController } from '@ionic/angular';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { FilterComponent } from '../../filter/filter.page';
import { GalleryPhotosComponent } from '../../GalleryPhotos/GalleryPhotos.component';

@Component({
  selector: 'app-card1', //ESTE ES EL TEMPLATE DEL CARD PARA LOS PRODUCTOS HORIZONTALES CON OPCION DE FAVORITOS
  templateUrl: './card1.component.html',
  styleUrls: ['./card1.component.scss'],
})
export class Card1Component implements OnInit, AfterViewInit {
  @ViewChild("slides", {static: false}) slides: IonSlides;
  @ViewChild("cardBody",{static:false}) containerCard!: ElementRef;
  @Input() heart: boolean;
  @Input() item: ProductInterface;
  @Input() ranking: number;
  @Input() isANewElement: boolean = false;
  @Input() featuredStyle: boolean = false;
  currentCategory: string = '';
  categories: {name: string, class:string}[] = [
    {name:'Inicio', class:'home'},
    {name:'Gastronomía', class:'gastronomy'},
    {name:'Tecnología', class:'technology'},
    {name:'Salud y Bienestar', class:'health'},
    {name:'Educación y Deportes', class:'sport'},
    {name:'Turismo', class:'turism'},
    {name:'Retail', class:'retail'},
    {name:'Entretenimiento', class:'entertainment'},
    
  ];
  public slideBannerOptions = {
    // autoplay: true,
    autoplay: false
  }
  public loadedProductImg:boolean = false;
  public styleColorDiscount: SafeStyle;
  containerClass: string = '';
  constructor(
    private ClubFibexServ: ClubFibexFeedingService,
    private navCtrl: NavController,
    private sanitizier: DomSanitizer,
    private modalCtrl: ModalController,
  ) { 
    const makeColor = () => {
      return (Math.round((Math.random() * (0xAA - 0x33))) + 0x33).toString(16).padStart(2, "0")
    }
    this.styleColorDiscount = this.sanitizier.bypassSecurityTrustStyle(
      "color: #" + makeColor() + makeColor() + makeColor()
    );
  }

  ngOnInit(): void { 
    const index = this.categories.findIndex(eCategory => eCategory.name === this.item.Categoria)
    this.currentCategory = this.categories[index].class
  }
  ngAfterViewInit(): void {
    // this.applyContainerClass();
  }
  public async addFavoriteItem(item: ProductInterface): Promise<void> {
    await this.ClubFibexServ.handleProductFav(item)
  }

  public goToRestDetail(): void {
    // this.router.navigate(['club-fibex/product-details', this.item.id, false])
    this.navCtrl.navigateRoot(['club-fibex/product-details', this.item.id, false])
  }

  public parseString(image: string | string[] | unknown): unknown | string[] {
    if (Array.isArray(image)) return image
    if (typeof image === "string") return JSON.parse(image)
    return null
  }

  slideNext() {
    if (this.slides) this.slides.slideNext()
  }

  slidePrev() {
    if (this.slides) this.slides.slidePrev() 
  }
  getColorRanking(value: number):string{
    let color: string = "ranking_container ";
    if(value<4) color+="ranking-top-place";
    else if(value<7)color+="ranking-mid-place";
    else color+="ranking-last-place";
    return color;
  }
  applyContainerClass() {
    const containerWidth = this.containerCard.nativeElement.offsetWidth;
    if (containerWidth > 172) {
      this.containerClass = 'food_details_cards_larger'; 
    } else {
      this.containerClass = 'food_details_cards_small';
    }
    
  }
  parseStringToNumber(value: string): number {
    return Number(value);
  }
}