<div [ngClass]="isModalMode ? 'backdrop_blur' : ''" [classList]="styleClass"  >
    <div [ngClass]="isModalMode ? 'modal_container animated fadeInUp' : 'h-max-300'" (scroll)="infiniteScroll($event)">
      <ion-header mode="ios" >
        <ion-toolbar mode="ios" *ngIf="isModalMode">
          <ion-buttons slot="start" mode="ios">
            <ion-button (click)="cancel()" color="dark">
              <ion-icon name="close"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title>Selecciona un aliado</ion-title>
          <ion-buttons slot="end" mode="ios">
            <ion-button (click)="confirmChanges()" color="dark">
              <ion-icon name="arrow-forward-outline" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-toolbar mode="ios">
          <ion-searchbar mode="ios" (ionInput)="searchbarInput($event)"></ion-searchbar>
        </ion-toolbar>
      </ion-header> 
      
      <!-- <ion-content color="light" class="ion-padding" mode="ios"> -->
        <!-- </ion-content> -->
        <div class="content_list">
          <ion-list class="border_radius_20" id="modal-list" [inset]="true" overflow-scroll="true"  >
            <ion-item *ngFor="let item of filteredItems; trackBy: trackItems" mode="ios">
              <!-- <ion-checkbox mode="ios" [value]="item" [checked]="isChecked(item.Nombre)" (ionChange)="checkboxChange($event)">
                </ion-checkbox> -->
                <div class="checkbox-wrapper-24">
                  <input type="checkbox" [id]="item.id" name="check" [value]="item.id" [checked]="isChecked(item.id)" 
                    (click)="checkboxChange($event)" />
                  <label [for]="item.id">
                    {{ item.name | uppercase }}
                    <span><!-- This span is needed to create the "checkbox" element --></span>
                  </label> 

                </div>
            </ion-item>
            <div *ngIf="loadingData">
                <ion-item *ngFor="let element of [0,1,2,3,4,5,6,7,8,9];" mode="ios">
                    <!-- <ion-checkbox mode="ios" [value]="item" [checked]="isChecked(item.Nombre)" (ionChange)="checkboxChange($event)">
                      </ion-checkbox> -->
                      <div class="checkbox-wrapper-24 loading">
                       
                      </div>
                  </ion-item>
            </div>
          </ion-list>
        </div>
    </div>
  </div>