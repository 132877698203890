import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/authGuard/auth.guard';
import { HomeGuard } from './guards/homeGuard/home.guard';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./pages/walkthrough/walkthrough.module').then(m => m.WalkthroughPageModule), canActivate: [AuthGuard],
  },
  // { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule), canActivate:[HomeGuard]},
  // { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule), canActivate: [AuthGuard] },
  {
    path: 'new-login',
    loadChildren: () => import('./pages/new-login/new-login.module').then(m => m.NewLoginPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'club-fibex',
    loadChildren: () => import('./pages/fibex-club/club-tabs/club-tabs.module').then(m => m.ClubTabsModule),
    canActivate:[HomeGuard]
  },
  {
    path: 'push-message',
    loadChildren: () => import('./pages/push-message/push-message.module').then( m => m.PushMessagePageModule),
    canActivate: [HomeGuard]
  },
  // {
  //   path: 'club-fibex/club-iframe',
  //   pathMatch: 'full',
  //   loadChildren: () => import('./pages/fibex-club/club-iframe/club-iframe.module').then( m => m.ClubIframePageModule),
  //   canActivate: [HomeGuard]
  // },
  {
    path: '**', redirectTo: 'club-fibex/home'
  }





];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
