import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ProductInterface } from '../../../../interfaces/product.interface';
import { PromotionService } from '../../../../services/PromotionService.service';
import { UserAuthenticationService } from '../../../../services/user-authentication.service';
import { IPagination } from '../../../../interfaces/IPagination.interface';

@Component({
  selector: 'app-container-promotions',
  templateUrl: './container-promotions.component.html',
  styleUrls: ['./container-promotions.component.scss'],
})
export class ContainerPromotionsComponent implements OnInit {

  @Input() typePromotions: '' | 'promo' | 'featuredPromotions' | 'recentPromotions' | 'rankingPromotions' = "";

  @Input() titleContainer: string = "";
  @Input() cssClass: string = "product-itemcard-2columns";
  @Input() pagination: boolean = false;
  @Input() promotions: ProductInterface[] = [];
  @Input() areLoadingPromotions: boolean = true;

  //Outputs 
  @Output() onClickButtonTitle: EventEmitter<any> = new EventEmitter;
  @Output() onScrollEnd: EventEmitter<IPagination> = new EventEmitter
  //Public variables
  public areAllProductsLoaded: boolean = false;
  //private variables
  private limitPage: number = 15;
  constructor(

  ) {
    // TODO: Get promotions from API or service

  }

  async ngOnInit() {
    // TODO: Get promotions from API or service
  }

 async onInfiniteScroll() {
  const scrollEvent = event.target as HTMLElement;

    if( scrollEvent.scrollLeft >= scrollEvent.scrollWidth - scrollEvent.offsetWidth && this.pagination){{
    
        const offset = this.promotions.length>0  ? Math.round(this.promotions.length/this.limitPage)+1 : 1;
        
        // this.areLoadingProducts = true;
        console.log("areLoadingProducts");
        this.onScrollEnd.emit({currentPage: offset, limitPage: this.limitPage})
    }
    //   this.areLoadingProducts= true;
    //   this.currentPage++;

    // const user = this.user.getUser();
    //   const getMorepromotions: ProductInterface[] = await this.promotionService.getSomePromotions("",user.nro_contrato,this.currentPage,this.limitPage);

    //   if(getMorepromotions.length>0){
    //     await new Promise(resolve => setTimeout(resolve, 500));
    //     this.promotions.push(...getMorepromotions)
    //   }
    //   this.areAllProductsLoaded = (getMorepromotions.length<this.limitPage);
    //   this.areLoadingProducts = false;
    }
  }
  public handleSliderNav = (elemScroll: HTMLDivElement, direction: 'left' | 'right'): void => {
    const element = elemScroll.querySelector(".product_slider") as HTMLDivElement | null;
    if(element) {
      element.scrollBy({left: direction === 'right' ? 272 : -272, behavior:'smooth'})
    }
  }
  public onClickTitleAction(){
    this.onClickButtonTitle.emit();
  }
  public isDesktop(): boolean { 
    return window.innerWidth > 768;
  }
}
