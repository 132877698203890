<div class="backdrop_blur animated fadeIn">
  <div class="modal1_container animated fadeInUp">
    <header class="modal1_header">
      {{title}}
    </header>
    <p *ngIf="message" class="modal1_message">
      {{message}}
    </p>
    <div class="modal1_content">

      <img [lazyLoad]="modalImage" [defaultImage]="'../../../../../assets/img/product_box_banner.jpg'"
        *ngIf="modalImage" [alt]="'Imagen '+title" class="modal1_image">

      <div class="form-container" *ngIf="formPlaceholder">
        <input class="modal1_input" type="text" [placeholder]="formPlaceholder" [value]="formValue"
        maxlength="6" [(ngModel)]="formValue" (input)="onInputValue(inputNumber)" #inputNumber />
      </div>

      <div class="btns_container" *ngIf="successTextBtn || cancelTextBtn" [class.gap1]="cancelTextBtn">
        <button *ngIf="cancelTextBtn" class="button-cancel" role="button"
          (click)="onCancelEvent()">{{cancelTextBtn}}</button>
        <button *ngIf="successTextBtn" class="button-33 success" role="button"
          (click)="successEvent()">{{successTextBtn}}</button>
      </div>
    </div>
  </div>
</div>