<!-- success-error-card.component.html -->
<ion-card class="card fadeIn" [ngClass]="type">
  <ion-header class="card-header">
    <!-- <ion-icon name="close-circle-outline"  (click)="onClose()"></ion-icon> -->
  </ion-header>
  <ion-card-content class="card-body">
   
    <div class="container-text"> 
      <h3>{{ title }}</h3>
      <p>{{ message }}</p>
      <div class="buttons-container">
        <ion-button *ngFor="let button of buttonList" expand="block" class="action-btn" [ngClass]="type" (click)="button.onClick()">
          {{ button.titleButton }}
        </ion-button>
      </div>
      
    </div>
  
  </ion-card-content>
</ion-card>
<div class="icon"  [ngClass]="type">
  <ion-icon [name]="iconName"></ion-icon>
</div>