<ion-app>
  <ion-split-pane   contentId="menu-content">

    <ion-menu [side]=" isExternalAuth() ? 'end' : 'start'"  contentId="menu-content" *ngIf="ShowMenu">

      <ion-header class="app-header">
        <ion-toolbar class="user-profile custom_header" [ngClass]="{'padding_iframe': isExternalAuth()}">

          <ion-item class="no_margins">
            <ion-avatar slot="start" class="user-avatar" style="margin-right: 5px;">
              <img defer async loading="lazy" [src]="UrlAvatar" style="padding: 16%;">
            </ion-avatar>
            <ion-label>
              <ion-text *ngIf="user">
                <h1 style="font-weight: bold; color: #ffffff;"> {{ subscriberUserName.substring(0,14) }} </h1>
              </ion-text>
              <ion-text *ngIf="user" style="color: #c8f8c6;">
                {{ subscriberUserEmail }}
              </ion-text>
            </ion-label>
          </ion-item>

        </ion-toolbar>
      </ion-header>

      <ion-content class="bg-profile">

        <div *ngIf="optionsMenu.length" class="list_container">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of optionsMenu">
            <div *ngIf="p.showMenu" (mousedown)="p.onClick ? p.onClick() : navigate(p.url)" (click)="p.onClick ? p.onClick() : navigate(p.url)" color="primary">
              <ion-icon *ngIf="p.img === ''" slot="start" [name]="p.icon"></ion-icon>
              <img defer async loading="lazy" width="30" height="34" *ngIf="p.img !== ''" [src]="p.img" [alt]="p.title">
              <ion-label>
                {{p.title}}
              </ion-label>
            </div>
          </ion-menu-toggle>
        </div>

        <div *ngIf="!optionsMenu?.length" class="list_container">
          <div *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14]" class="menu-toggle-loading loading medium-mode"></div>
        </div>


        <div class="version-container color_app_blue">
          <ion-text style="font-size: 10px;" (click)="_log.IncrementTrysToOpen()">
            {{VersionApp}}
          </ion-text>
        </div>
      </ion-content>

    </ion-menu>

    <ion-router-outlet id="menu-content">
      <app-loader></app-loader>
    
    </ion-router-outlet>

  </ion-split-pane>
</ion-app>






