import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SeguridadDatos } from './bscript.service';
import { environment as env } from '../../environments/environment';
import { AlertController, NavController } from '@ionic/angular';
import { timeout } from 'rxjs/operators';
import { IIframeRenderer, IShareInterface } from '../interfaces/IIframeRenderer.metadata';

@Injectable({
    providedIn: 'root'
})

export class HelperService {
    private currentOptionsSubject: BehaviorSubject<any> = new BehaviorSubject({});
    private currentIframeRendererSubject: BehaviorSubject<IIframeRenderer> = new BehaviorSubject({ url: '', title: '' });
    public showScrollArrowSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private menu: Subject<any> = new Subject<any>()

    public readonly currentOptions: Observable<any> = this.currentOptionsSubject.asObservable();
    public readonly currentIframeRenderer: Observable<any> = this.currentIframeRendererSubject.asObservable();

    auxMenu = new EventEmitter<any>()
    constructor(
        private http: HttpClient,
        private crypto: SeguridadDatos,
        private navigate: NavController,
        private alert: AlertController
    ) {

    }

    setOptionsMenu(optionsMenu: any) {
        this.currentOptionsSubject.next(optionsMenu);
    }

    setIframeToRender(values: IIframeRenderer) {
        this.currentIframeRendererSubject.next(values);
    }

    retrievePlatform() {
        const platform = navigator.userAgent.toLowerCase().replace(/\W+/g, "")

        const matched = platform.match(/smarttv/i)
            || this.GetFireTV(platform)
            || platform.match(/iphone/i)
            || platform.match(/ipad/i)
            || platform.match(/ipod/i)
            || platform.match(/blackberry/i)
            || platform.match(/android/i)
            || platform.match(/webos/i)
            || platform.match(/windowsphone/i)
            || platform.match(/windows/i)
            || platform.match(/mac/i)
            || platform.match(/linux/i)
            || platform.match(/eaglewatch/i)

        if (matched) {

            const platform = matched[0];

            switch (platform) {
                case "linux":
                case "mac":
                case "windows":
                    return "desktop"
                    break;

                case "smarttv":
                case "webos":
                case "firetv":
                    return "tv"
                    break;

                case "android": // moviles
                case "blackberry":
                case "windowsphone":
                case "eaglewatch":
                case "ipod": // tables
                case "ipad":
                    return "phone"
                    break;

            }
        }
    }

    GetFireTV(platform: string) {
        if (platform.includes("android")) {
            const models = [
                "AFTKA002",
                "AFTKAUK002",
                "AFTHA004",
                "AFTLBT962E2",
                "AEOHY",
                "AFTTIFF43",
                "AFTGAZL",
                "AFTANNA0",
                "AFTHA001",
                "AFTMON001",
                "AFTMON002",
                "AFTJULI1",
                "AFTANNA0",
                "AFTHA003",
                "AFTKA",
                "AFTLFT962X3",
                "AFTTIFF43",
                "AFTTI43",
                "AFTHA001",
                "AFTPR001",
                "AFTBU001",
                "AFTWMST22",
                "AFTTIFF55",
                "AFTWI001",
                "AFTSSS",
                "AFTSS",
                "AFTDCT31",
                "AFTDCT31",
                "AFTT",
                "AFTBAMR311",
                "AFTEAMR311",
                "AFTKMST12",
                "AFTLE",
                "AFTR",
                "AFTEUFF014",
                "AFTEU014",
                "AFTSO001",
                "AFTMM",
                "AFTEU011",
                "AFTJMST12",
                "AFTA",
                "AFTMM",
                "AFTT",
                "AFTRS",
                "AFTN",
                "AFTS",
                "AFTM",
                "AFTB",
                "AFTMM",
                "AFTHA002",
            ].map(i => i.toLowerCase());

            const result = models.find(m => platform.includes(m));

            if (result) return ["firetv"];
        }
    }

    public listenYourVariable() {
        return this.menu.asObservable();
    }


    public yourVariableObserver(value: boolean) {
        this.menu.next(value);
    }

    public handleShowScrollArrow(ev): boolean {
        return (ev.detail.scrollTop > 0)
    }

    formatLocaleCurrency(number: string) {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = '$1.';
        let arr = number.toString().split('.');
        arr[0] = arr[0].replace(exp, rep);
        return arr[1] ? arr.join(',') : arr[0];
    }
    redirectToURL(url: string, message: string){
        if(url && url.length>0){
            const regexWaMe =   /^(https?:\/\/wa\.me\/)(\+?\d{1,7}[-\s]?)?\d+$/
         
      
      
            const urlRedirect = url.replace(" ","").trim();
            if(regexWaMe.test(urlRedirect)){
              const arr = urlRedirect.split("/");
              arr[arr.length-1] = arr[arr.length-1].replace("-","");
              const redirection = `${arr.join("/")}?text=${message}`;
              window.open(redirection, '_blank')
            }
          else{ 
            window.open(url, '_blank')
          }
    }
    }
    redirectToURLV2(urlRed: string){
        
        const AndroidShareHandler: undefined | IShareInterface = (window as any).AndroidShareHandler;
        if(AndroidShareHandler) {
            const intentUrl = urlRed.replace(/https?:\/\//, 'intent://');
    
            // Construir el Intent completo
            const finalUrl = `${intentUrl}#Intent;scheme=https;end`;
        
            // Redirigir al Intent para abrir en Chrome u otro navegador
            window.location.href = finalUrl;
        }
        else {
        //   const element = document.createElement("a");
        //   element.href = url;
        //   element.target = "_blank"
        //   element.click();
        window.open(urlRed, '_blank')
        }
    }
    FormatPhone(Phone: any) {
        const initPhone = Phone.substring(0, 3)
        if (Phone.charAt(0) === '0' && initPhone != '000') {
            const newphone = `58${Phone.substring(1, Phone.length)}@c.us`
            return newphone
        } else {

            if (initPhone === '414' || initPhone === '424' || initPhone === '412' || initPhone === '416' || initPhone === '426') {
                return `58${Phone}@c.us`
            } else if (initPhone != '000') {
                return `${Phone}@c.us`
            }
        }
    }

}
