import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { EstadisticasService } from './estadisticas.service';
import { NavigationEnd, NavigationStart, Router, Event, Params, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { QueryActions } from '../interfaces/query-actions.interface';

@Injectable({
  providedIn: 'root'
})
export class RoutingAppService {

  private routerSub: Subscription
  public showSpinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(
    private navCtrl: NavController,
    private _estadistica: EstadisticasService,
    private _router: Router,
    private _act_Route: ActivatedRoute,
  ) { }

  ChangeRuta( Ruta: string ) {
    
    if(Ruta === 'new-login' || Ruta === '/home') this.navCtrl.navigateRoot( [Ruta], {replaceUrl:true})


    this.navCtrl.navigateForward(Ruta);
    this._estadistica.RegisterAccion(Ruta)
    this._estadistica.RutaActual = Ruta
  }

  //* to set showSpinnerSubject true if the routing is loading | or false if routing is loaded
  public subRouterEvents = (): void => {
    this.routerSub = this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.showSpinnerSubject.next(true);
      }

      if (routerEvent instanceof NavigationEnd ) {
        this.showSpinnerSubject.next(false);
      }
    })
  }

  //* get the value of showSpinnerSubject
  public getShowSpinnerSubject = (): Observable<boolean> => {
    return this.showSpinnerSubject.asObservable()
  }

  public setShowSpinnerValue(value: boolean){
    this.showSpinnerSubject.next(value);
  }
  loadRouterParamsCat(): QueryActions {
    let actions: QueryActions = {};
    
    const paramSub = this._act_Route.queryParamMap.subscribe((data: Params) => {
      const { params } = data;
  
      if (params.qCategory) {
        actions.qCategory = params.qCategory.split('--');
      }
  
      if (params.qPricing) {
        actions.qPricing = params.qPricing.split('--');
      }
  
      if (params.qDiscountType) {
        actions.qDiscountType = params.qDiscountType;
      }
  
      if (params.qCities) {
        actions.qCities = params.qCities.split('--');
      }
  
      if (params.qCompanies) {
        actions.qCompanies = params.qCompanies.split('--');
      }
  
      if (params.qtypePromoo) {
        actions.qtypePromoo = params.qtypePromoo;
      }
  
      if (params.qTypeSearch) {
        actions.qTypeSearch = params.qTypeSearch;
      }
  
      if (params.qSracthWin) {
        actions.qSracthWin = Number(params.qSracthWin);
      }
    });
  
    return actions;
  }
  public isFilteringApplied(actions: QueryActions): boolean {
    // Valores por defecto
    const defaultValues = {
      qDiscountType: 'discount',
      qPricing: ['0', '1000'],
      qSracthWin: 0,
      qTypeSearch: '0'
    };
  
    // Verificar si se está aplicando algún filtro diferente a los valores por defecto
    if (actions.qCategory || 
        actions.qCities || 
        actions.qCompanies ||
        (actions.qDiscountType && actions.qDiscountType !== defaultValues.qDiscountType) ||
        (actions.qPricing && JSON.stringify(actions.qPricing) !== JSON.stringify(defaultValues.qPricing)) ||
        (actions.qSracthWin && actions.qSracthWin !== defaultValues.qSracthWin) ||
        (actions.qTypeSearch && actions.qTypeSearch !== defaultValues.qTypeSearch)) {
      return true;
    }
  
    // Verificar si no se aplica ningún filtro (todos los valores están vacíos)
    if (!actions.qCategory && !actions.qPricing && !actions.qDiscountType && !actions.qCities && !actions.qCompanies && !actions.qTypeSearch && !actions.qSracthWin) {
      return false;
    }
  
    return false;
  }
  
  
}
