import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CompanyInterface } from '../../../../interfaces/product.interface';
import { Router } from '@angular/router';
import { CompanyService } from '../../../../services/company.service';

@Component({
  selector: 'app-container-companies',
  templateUrl: './container-companies.component.html',
  styleUrls: ['./container-companies.component.scss'],
})
export class ContainerCompaniesComponent implements OnInit {

  @Input() companies: CompanyInterface[] = []
  @Input() titleContainer: string = "";
  //public variables
  public allCompanies: CompanyInterface[] = [];
  public areLoadingCompanies: boolean = false;
  public areAllCompaniesLoaded: boolean = false;
  //private variables
  private limit: number = 15;
  private offset: number = 1;
  constructor(    
    private router: Router,
    private companyService: CompanyService,
  ) { }

  @Output() onClickButtonTitle: EventEmitter<any> = new EventEmitter;
  
  ngOnInit() {
    this.areLoadingCompanies = true;
    this.updateCompaniesData();
  }
  public onInfiniteScroll = async (event:Event,typeScroll: string) => {
    event.stopPropagation();
    const scrollEvent = event.target as HTMLElement;
    if (scrollEvent.scrollLeft >= scrollEvent.scrollWidth - scrollEvent.offsetWidth) {
    console.log("Infinite Scroll");
      switch(typeScroll){
        case "companies":
          this.offset++;
          this.updateCompaniesData();
        break;
      }
    }
  }

  public goToCompanyProfile(companyId: string): void {
    this.router.navigate(['club-fibex', 'company-profile', companyId], { replaceUrl: false })
  }
  public onClickTitleAction(){
    this.onClickButtonTitle.emit();
  }
  public onGetMoreCompanies(){

  }


   /** 
   * Updates the list of companies by fetching more data from the server.
   * This method is used for pagination, loading additional companies when needed.
   * @returns {Promise<void>} A promise that resolves when the update operation is complete.
   * @remarks
   * This method will only fetch more companies if not all companies have been loaded yet.
   * It updates several class properties://+
   * - Increments the `offset` for pagination//+
   * - Adds newly fetched companies to `allCompanies`//+
   * - Updates `areAllCompaniesLoaded` based on the number of companies fetched
   * - Manages the `areLoadingCompanies` flag to indicate loading state
   * @throws Will throw an error if the company service fails to fetch data.
   */ 
  public async updateCompaniesData(): Promise<void> {//+
    if(!this.areAllCompaniesLoaded){
      this.areLoadingCompanies= true;//-
      this.areLoadingCompanies = true;//+
      const getMoreCompanies: CompanyInterface[] = await this.companyService.GetCompaniesPaginated(this.limit,this.offset);
      if(getMoreCompanies.length>0){
        await new Promise(resolve => setTimeout(resolve, 500));
        this.allCompanies.push(...getMoreCompanies)
      }
      this.areAllCompaniesLoaded  = (getMoreCompanies.length<this.limit);
      this.areAllCompaniesLoaded = (getMoreCompanies.length<this.limit);
      this.areLoadingCompanies = false;
    }
  }
    //* To handle scrolling product slider, (Only available on PC)
  public handleSliderNav = (elemScroll: HTMLDivElement, direction: 'left' | 'right'): void => {
      const element = elemScroll.querySelector(".product_slider") as HTMLDivElement | null;
      console.log(element);
      
      if(element) {
        element.scrollBy({left: direction === 'right' ? 272 : -272, behavior:'smooth'})
      }
  }

  public isDesktop(): boolean { 
    return window.innerWidth > 768;
  }
}
