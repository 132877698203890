import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-title-section-container',
  templateUrl: './title-section-container.component.html',
  styleUrls: ['./title-section-container.component.scss'],
})
export class TitleSectionContainerComponent implements OnInit {

  @Input() title: string = "";
  @Output() eventButton: EventEmitter<any> = new EventEmitter;
  constructor() { }

  ngOnInit() {}

  executeAction() {
    this.eventButton.emit();
  }
}
