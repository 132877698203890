import * as tslib_1 from "tslib";
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { IonSlides, ModalController, Platform } from '@ionic/angular';
import { UserAuthenticationService } from '../../.././services/user-authentication.service';
export class GalleryPhotosComponent {
    constructor(modalControler, platform, auth) {
        this.modalControler = modalControler;
        this.platform = platform;
        this.auth = auth;
        this.images = [];
        this.imagesPresent = [];
        this.isDesktop = false;
        this.isZoomed = false;
        this.loadedReferImg = false;
        this.page = 1;
        this.sliderOptions = { slidesPerView: 1,
            loop: true,
            pagination: true,
            //     zoom: {
            //       maxRatio: 3,  // Límite máximo del zoom in
            //       minRatio: 1.5, // Límite mínimo del zoom out
            // },
            // allowTouchMove: false,
            touchEventsTarget: 'container',
            passiveListeners: false,
            on: {
                zoomChange: (scale) => {
                    this.handleZoomChange(scale);
                }
            }
        };
        this.slideChanged = (ev) => {
            this.slideImage.getSwiper().then(swiper => {
                this.isZoomed = false;
                if (swiper.zoom.scale !== 1) {
                    swiper.allowSlideNext = false;
                    swiper.allowSlidePrev = false;
                }
                else {
                    swiper.allowSlideNext = true;
                    swiper.allowSlidePrev = true;
                }
            });
            this.slideImage.getActiveIndex().then(resp => {
                if (resp > this.images.length)
                    this.page = 1;
                else if (resp < 1)
                    this.page = this.images.length;
                else
                    this.page = resp;
            });
        };
        this.handleSliderNav = (elemScroll, direction) => {
            // const element = elemScroll.querySelector(".product_slider") as HTMLDivElement | null;
            direction == 'right' ? this.slideImage.slideNext() :
                this.slideChanged();
            this.slideImage.slidePrev();
            // if(element) {
            //   element.scrollBy({left: direction === 'right' ? 272 : -272, behavior:'smooth'})
            // }
        };
    }
    ionViewDidEnter() {
        this.isDesktop = (this.platform.is('desktop'));
        //   if(this.isDesktop) this.disableDoubleClick();
        this.slideImage.update();
        //   if(this.isDesktop){
        // this.sliderOptions.zoom.toggle = !this.isDesktop; // Desactiva el zoom con doble clic o doble toque
        // }
    }
    ngOnInit() {
    }
    ngAfterViewInit() {
        this.imagesPresent = this.images;
        this.loadedReferImg = true;
        this.page = this.currentImage.value;
        if (this.currentImage.value != 1)
            this.slideTo(this.currentImage.value);
        // this.slideImage.update();
    }
    toggleZoom(event) {
        const img = event.target;
        if (img.classList.contains('zoomed')) {
            img.classList.remove('zoomed');
        }
        else {
            img.classList.add('zoomed');
        }
    }
    onBackNavigation() {
        this.modalControler.dismiss();
    }
    slideTo(index) {
        this.slideImage.slideTo(index);
    }
    disableDoubleClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const swiper = yield this.slideImage.getSwiper();
            const images = document.querySelectorAll('.swiper-slide img');
            images.forEach((img) => {
                img.addEventListener('dblclick', (event) => {
                    event.preventDefault(); // Previene el comportamiento predeterminado del doble clic
                    swiper.zoom.out(); // Resetea el zoom en caso de doble clic
                });
            });
        });
    }
    // Método para hacer zoom in
    zoomIn() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // if(this.isDesktop){
            const swiper = yield this.slideImage.getSwiper();
            const currentZoom = swiper.zoom.scale;
            if (currentZoom < this.sliderOptions.zoom.maxRatio) {
                swiper.zoom.in(); // Acercar el zoom
            }
            this.checkSlideMovable(swiper);
            // }
        });
    }
    // Método para hacer zoom out
    zoomOut() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // if(this.isDesktop){
            const swiper = yield this.slideImage.getSwiper();
            const currentZoom = swiper.zoom.scale;
            if (currentZoom >= this.sliderOptions.zoom.maxRatio) {
                swiper.zoom.out(); // Alejar el zoom
            }
            this.checkSlideMovable(swiper);
            // }
        });
    }
    // Controla si el slider puede moverse al siguiente o anterior slide según el zoom
    checkSlideMovable(swiper) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (swiper.zoom.scale > 1) {
                swiper.allowSlideNext = false;
                swiper.allowSlidePrev = false;
                this.isZoomed = true;
            }
            else {
                swiper.allowSlideNext = true;
                swiper.allowSlidePrev = true;
                this.isZoomed = false;
            }
        });
    }
    handleZoomChange(scale) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const swiper = yield this.slideImage.getSwiper();
            // Si el zoom es menor que el permitido, restablecer
            if (scale < this.sliderOptions.zoom.minRatio) {
                swiper.zoom.set(this.sliderOptions.zoom.minRatio);
            }
            this.isZoomed = scale > 1; // Actualiza el estado del zoom
        });
    }
    ngOnDestroy() {
        this.imagesPresent = [];
        this.images = [];
    }
}
