import { Injectable } from '@angular/core';
import { IndexDBService } from './index_db.service';
import { LocalStorageService } from './local-storage.service';
import { CategoryInteface } from "../interfaces/product.interface";
import { ExpirationTime } from '../providers/expiration-time';
import { ClubFibexFeedingService } from './club-fibex-feeding.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private categories: CategoryInteface[] = [];

  constructor(private indexDB: IndexDBService, private localStorage: LocalStorageService, private clubFibexAsync: ClubFibexFeedingService) {}

  async getCategories(): Promise<CategoryInteface[]> {
    try {
      // Verificar si los datos existen en IndexedDB
      const cachedCategories = await this.indexDB.getTable<CategoryInteface>('categories');
      const dataTable =await cachedCategories.findAll();      
      if (dataTable.length > 0) {
        // Comprobar si el token de expiración está vencido
        const cacheStatus = this.localStorage.getWithExpiry('categories');
        console.log(cacheStatus);
       
        if (!cacheStatus || cacheStatus.status === false) {
          // Token de expiración vencido, obtener nuevos datos de la base de datos remota y actualizar IndexedDB
          const newCategories = await this.fetchCategoriesFromServer();
          await cachedCategories.delete();
          await cachedCategories.insert(newCategories);  // Actualiza con el tiempo de expiración deseado
          // Actualizar el valor en localStorage
          this.localStorage.setWithExpiry('categories', 'categories', ExpirationTime.TwentyFourHours);
          return newCategories;
        } else {
          // Token de expiración no vencido, devolver los datos de IndexedDB
          return dataTable;
        }
      } else {
        // No hay datos en IndexedDB, obtener nuevos datos de la base de datos remota y guardar en IndexedDB
        const newCategories = await this.fetchCategoriesFromServer();
        await cachedCategories.insert(newCategories);  // Actualiza con el tiempo de expiración deseado
        // Actualizar el valor en localStorage
        this.localStorage.setWithExpiry('categories', 'categories', ExpirationTime.TwentyFourHours);
        return newCategories;
      }
    } catch (error) {
      console.error('Error obteniendo categorías:', error);
      return [];
    }
  }

  private async fetchCategoriesFromServer(): Promise<CategoryInteface[]> {
    try {
      const categories = await this.clubFibexAsync.GetCategories();
      console.log(categories);
      
      return categories;
    } catch (error) {
      return []
    }
  }
}
