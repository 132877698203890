import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { dbFull, dbFullDataBase } from '../lib/dbfullv2';
import { environment } from '../../environments/environment';
import axios, { AxiosResponse } from 'axios';
import { UserAuthenticationService } from './user-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class GifCardService {

  constructor(
    private localStorage: LocalStorageService,
    private userAuth: UserAuthenticationService
  ) { }

  dbFull: dbFullDataBase = new dbFullDataBase('thomas_tarjeta_regalos');

  public urlAPI: string = environment.apiTest;
  public giftCardObjectRout: any =  {
        "title": "Tarjeta Regalo",
        "url": "club-fibex/gift-card-home",
        "direct": "forward",
        "icon": "",
        "show": true,
        "showHome": true,
      "showMenu": true,
        // "image": 'assets/img/icons/Iconos2.png'
        "img": "assets/icons/gift_card.png",
        "image": "assets/icons/gift_card.png",
    };
  private cupones_history: any[] = [];

  public getCuponesHistory(): any[] {
    return this.cupones_history;
  }

  public setCuponesHistory(cupones: any[]): void {
    this.cupones_history = cupones;
  }

  public async fetchCuponesHistory(): Promise<any[]> {
    if (this.cupones_history.length === 0) {
      const limit = 30;
      const offset = 0;
      try {
        const cupones = await this.getHistoryGiftCardUser(limit, offset);
        this.setCuponesHistory(cupones);
        return cupones;
      } catch (error) {
        console.error('Error fetching cupones history:', error);
        throw error;
      }
    } else {
      return this.getCuponesHistory();
    }
  }

  public setGiftCardInLocalStorage(giftCard: any): void {
    const threeMinutes = 30 * 1000; // 3 minutos en milisegundos
    this.localStorage.setWithExpiry('saw', giftCard, threeMinutes);
  }

  public async fetchCardBalance(): Promise<any> {
    const result = this.localStorage.getWithExpiry('saw');
    console.log(result,"result");
    if (result.data !== undefined && result.status) {
      let  data = result.data;
      if(Array.isArray(data) ) return result.data[0];
        return  result.data;
    } else {
      const response = await this.getCardBalance();
      return response;
    }
  }

  public async getCardBalance(): Promise<any> {
    const apiEndpointExchange = environment.endpointConsult;
    try {
      const responseExchange: AxiosResponse<any> = await axios.get<any>(`${this.urlAPI}/${apiEndpointExchange}?idAbonado=${this.userAuth.getUser().nro_contrato}`);
      console.log(responseExchange);
      const response = responseExchange.data;
      if (response.hasOwnProperty("error")) throw response;
      
      // Guardar el balance en el Local Storage
      if(response.status)
      this.setGiftCardInLocalStorage(response.tarjetas);
      
      return response.tarjetas;
    } catch (error) {
      console.error('Error in exchangeGiftcard:', error);
      // Lanza el error para que pueda ser manejado en el componente
      throw error.response ? error.response.data : error;
    }
  }

  public async exchangeGiftcard(code: string): Promise<any> {
    const apiEndpointExchange = environment.endpointExchange;
    try {
      const dataResponse = { codigo: code, id_abonado: this.userAuth.getUser().nro_contrato };
      console.log(dataResponse);
      const responseExchange: AxiosResponse<any> = await axios.post<any>(`${this.urlAPI}/${apiEndpointExchange}`, dataResponse);
      console.log(responseExchange);
      const response = responseExchange.data;

      // Guardar el valor de 'giftcard' en el Local Storage
      if (response.giftcard) {
        this.setGiftCardInLocalStorage(response.giftcard);
      }

      if (response.hasOwnProperty("error")) throw response;
      return response;
    } catch (error) {
      console.error('Error in exchangeGiftcard:', error);
      // Lanza el error para que pueda ser manejado en el componente
      throw error.response ? error.response.data : error;
    }
  }

  public async getHistoryGiftCardUser(limit: number, offset: number): Promise<any> {
    const endpoint = environment.endpointHistory;
    try {
      const responseExchange: AxiosResponse<any> = await axios.get<any>(`${this.urlAPI}/${endpoint}?abonado=${this.userAuth.getUser().nro_contrato}&lim=${limit}&off=${offset}`);
      console.log(responseExchange);
      const response = responseExchange.data;
      if (response.hasOwnProperty("error")) throw response;
      else return response.cupones;
    } catch (error) {
      console.error('Error in exchangeGiftcard:', error);
      // Lanza el error para que pueda ser manejado en el componente
      throw error.response ? error.response.data : error;
    }
  }
}
