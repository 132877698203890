import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { RoutingAppService } from '../../../services/routing-app.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ModalService } from '../../../services/modal.service';
import { buttonInterface } from '../../../interfaces/button-interface';


interface buttonAlert{
  titleButton: string;
  onClick: () => void;
}

@Component({
  selector: 'app-modal-register-code',
  templateUrl: './modal-register-code.component.html',
  styleUrls: ['./modal-register-code.component.scss'], 
})

export class ModalRegisterCodeComponent implements OnInit {

  constructor(
    //Ionic Services
    private popOver: PopoverController,
    private alertCtrl: AlertController,
    //Services app
    
    private navigationServ: RoutingAppService,
    private localStorage: LocalStorageService,
    private modalServices: ModalService,
  ) { }

  @Input() cancelTextBtn: string =  'Cancelar';

  @Input() successTextBtn: string = 'Aceptar';
  @Input() title: string =  'Raspa y gana';
   @Input() formPlaceholder: string =  'Ingrese el código aqui';
  @Input() formType: 'text' | 'number' =  'text';
  @Input() message: string = 'Introduce el código ubicado en la parte posterior de la tarjeta de regalo';
  @Input() modalImage: string = '';
  public canShowModal: boolean = true;

  public formValue: string = '';

  ngOnInit() {
  }


  dismiss() {
    
    // this.canShowModal = false;
    this.popOver.dismiss();
  }
  successButtonClick(numberSucces: number){
  switch(numberSucces){
    case 0:
      // this.setStorageElement();
      
      const buttons: buttonInterface[] = [
        {
          titleButton: 'Cerrar',
        onClick: () => this.dismiss()
        }, 
        // {
        //     titleButton: 'Ver Opciones',
        //   onClick: () => this.redirectToScratch()
        // },
        {
          titleButton: 'Explorar promociones',
        onClick: () => {
          this.navigationServ.ChangeRuta('/club-fibex/home?qPricing=0--1000&qDiscountType=discount&qCities=&qCompanies=&qfilterCitiesByComp=false&qTypeSearch=0&qSracthWin=1&fromFilter=true')
          this.dismiss();
          // this.localStorage.set('saw','100');
        }
      }
     ];
    
      this.modalServices.generateModalSuccess("¡Felicidades!","Ha canjeado correctameente su tarjeta de regalo","Volver al inicio", buttons);
      this.dismiss();
      break;
    case 1:
     
      this.modalServices.generateModalError("¡Error!","El codigo de regalo es incorrecto por favor intente nuevamente","Aceptar", [  {
        titleButton: 'Cerrar',
      onClick: () => this.dismiss()
      }, ] );
      
      break;
    case 2:
      this.modalServices.generateModalError("¡Error!","El codigo de regalo ya ha sido usado por favor intente con otro","Aceptar",  [  {
        titleButton: 'Cerrar',
      onClick: () => this.dismiss()
      }, ]);
      
      break;
    default:
      break;
  }
  }

  async presentAlert(title: string, message: string, buttons: buttonAlert[], timeout?: number) {
    const alertButtons = buttons.map(button => ({ text: button.titleButton, handler: button.onClick}));
    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      // mode: 'ios',
      buttons: alertButtons
    });
 
    await alert.present();
    if (timeout) {
      setTimeout(() => {
        alert.dismiss();
        
      }, timeout);
    }
  }

  async redirectToScratch(){
    // this.setStorageElement();
    this.dismiss(); 
    this.navigationServ.ChangeRuta('club-fibex/scratch-and-win');
  }

  private setStorageElement(){
    const storageItemName = "saw";
    this.localStorage.set(storageItemName, {
      mount: "100$"
    });
  }
 
}
