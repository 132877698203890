import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { ExpirationTime } from '../providers/expiration-time';
interface IModelTable {
  name: string,
  fields: {
    name: string,
    index?: boolean,
    primary?: boolean,
    autoincrement?: boolean
  }[]
}


interface ITableFindCondition<T> {
  where?: {
    [key in keyof T]: T[key]
  },
  offset?: number
}

interface IField {
  name: string;
  primary?: boolean;
  autoincrement?: boolean;
  index?: boolean;
}

const categoryFields: IField[] = [
  { name: 'Status' },
  { name: 'icon' },
  { name: 'idEmpCat', primary: true },
  { name: 'img' },
  { name: 'imgWhite' },
  { name: 'nombre' },
  { name: 'color' },
  { name: 'orden' },
  { name: 'isLoaded' }
];


const promotionFields: IField[] = [
  { name: 'id', primary: true },
  { name: 'referImages' },
  { name: 'isDiscount' },
  { name: 'AplicaDelivery' },
  { name: 'CantCuponesUser' },
  { name: 'Categoria' },
  { name: 'CuponesDiponible' },
  { name: 'FechaFin' },
  { name: 'FechaIni' },
  { name: 'NombreProducto' },
  { name: 'Pickup' },
  { name: 'category' },
  { name: 'company' },
  { name: 'company_logo' },
  { name: 'description' },
  { name: 'discount' },
  { name: 'discountPrice' },
  { name: 'idEnSAE' },
  { name: 'idProducto' },
  { name: 'img' },
  { name: 'name' },
  { name: 'price' },
  { name: 'ranking' },
  { name: 'fav' },
  { name: 'idAbonado' },
  { name: 'LinkRedirect' },
  { name: 'LinkPromo' },
  { name: 'productPrice' },
  { name: 'productPercent' },
  { name: 'QRurl' },
  { name: 'idCupon' },
  { name: 'createdAt' },
  { name: 'updatedAt' },
  { name: 'orden' },
  { name: 'TipoCanjeo' },
  { name: 'CodigoFijo' },
  { name: 'companyid' },
  { name: 'aceptaCred' },
  { name: 'montoCred' }
];

const couponFields: IField[] = [
  { name: 'companyid' },
  { name: 'FechaFin' },
  { name: 'FechaLimite' },
  { name: 'QRurl' },
  { name: 'QR' },
  { name: 'LinkRedirect' },
  { name: 'LinkPromo' },
  { name: 'idAbonado' },
  { name: 'category' },
  { name: 'company' },
  { name: 'company_logo' },
  { name: 'createdAt' },
  { name: 'description' },
  { name: 'discount' },
  { name: 'discountPrice' },
  { name: 'id', primary: true },
  { name: 'idCupon' },
  { name: 'img' },
  { name: 'isDiscount' },
  { name: 'name' },
  { name: 'price' },
  { name: 'usado' },
  { name: 'TipoCanjeo' },
  { name: 'idEmpConv' },
  { name: 'Comentario' },
  { name: 'rating' },
  { name: 'idSucursal' },
  { name: 'idProducto' }
];

const companyFields: IField[] = [
  { name: 'Orden' },
  { name: 'UrlConvenio' },
  { name: 'id', primary: true },
  { name: 'idEnSAE' },
  { name: 'img' },
  { name: 'name' },
  { name: 'rif' },
  { name: 'namesucursal' },
  { name: 'companyBanner' },
  { name: 'ordenFeat' },
  { name: 'aceptaCred' },
  { name: 'category' }
];
const locationFields: IField[] = [
  { name: 'Nombre' },
  { name: 'idEnSAE' },
  { name: 'idSucursal', primary: true }
];

const modelsFibextelecom: IModelTable[] = [
  { name: 'promotions', fields: promotionFields },
  { name: 'featured_promotions', fields: promotionFields },
  { name: 'ranking_promotions', fields: promotionFields },
  { name: 'favroite_promotions', fields: promotionFields },
  { name: 'companies', fields: companyFields },
  { name: 'locations', fields: locationFields },
  { name: 'coupons', fields: couponFields },
  { name: 'categories', fields: categoryFields }
];
/**
 * Interface para trabajar sobre objectStorage
 */
export interface ITable<T extends {}> {
  /**
   * Obtener nombre de tabla
   */
  getName(): string,
  /**
   * Obtener todos los registros
   */
  findAll(condition?: ITableFindCondition<T> & {limit?: number}): Promise<T[]>,
  /**
   * Obtener el primer registro o null si no encuentro nada
   */
  findOne(condition?: ITableFindCondition<T>): Promise<T | null>,
  /**
   * Permite insertar registros
   * @param reg Dato(s) a insertar
   */
  insert(reg: T): Promise<T>,
  insert(reg: T[]): Promise<T[]>,
  /**
   * Permite eliminar registros
   */
  delete(condition?:  ITableFindCondition<T>): Promise<void>,
  /**
   * Permite actualizar uno o mas registros
   * @param newData Nuevo datos
   * @param condition Condision de actualizacion
   */
  update(newData: {[key in keyof T]?: T[key]}, condition?: ITableFindCondition<T>): Promise<{ n_affected: number }>,
}

// const modelsFibextelecom: IModelTable[] = []
@Injectable({
  providedIn: 'root'
})
export class IndexDBService {

  private db: IDBDatabase | null;
  private initializingDB: {
    resolve: (db: IDBDatabase) => void,
    reject: (err: unknown) => void,
  }[] = [];

  constructor(private localStorage: LocalStorageService) {
  }

  private inicializeDB(database: string, models: IModelTable[]) {
    return new Promise<IDBDatabase>((resolve, reject) => {
      try {
        const requestOpen = indexedDB.open(database);

        requestOpen.onsuccess = function() {
          resolve(requestOpen.result)
        };

        requestOpen.onerror = function(ev) {
          reject(requestOpen.error);
        };

        requestOpen.onupgradeneeded = function(ev) {
          const db = requestOpen.result;

          for(const modelItem of models) {
            const autoincrement = modelItem.fields.find(fieldItem => fieldItem.primary && fieldItem.autoincrement);
            const primary = modelItem.fields.find(fieldItem => fieldItem.primary);
            const fields = modelItem.fields.filter(fieldItem => fieldItem !== primary && fieldItem !== autoincrement);
            const storage = db.createObjectStore(modelItem.name, {
              keyPath: ((autoincrement || primary) && (autoincrement || primary).name),
              autoIncrement: !!autoincrement
            });

            // Crear indexados
            fields.forEach(fieldItem => {
              const index = storage.createIndex(fieldItem.name + "_index", fieldItem.name, { unique: fieldItem.index });
            });
          }
        }
      }
      catch(err) {
        reject(err);
      }
    })
  }

  private getDatabase() {
    return new Promise<IDBDatabase>((resolve, reject) => {
      try {
        if(this.db) {
          resolve(this.db);
        }
        else {
          if(!this.initializingDB.length) {
            this.inicializeDB("fibextelecom", modelsFibextelecom)
              .then((db) => {
                this.db = db;

                const temp = this.initializingDB;
                this.initializingDB = [];

                temp.forEach(initializingItem => {
                  initializingItem.resolve(db);
                });
              })
              .catch(err => {
                const temp = this.initializingDB;
                this.initializingDB = [];

                temp.forEach(initializingItem => {
                  initializingItem.reject(err);
                });
              })
          }
          this.initializingDB.push({
            resolve,
            reject
          });
        }
      }
      catch(err) {
        reject(err);
      }
    })
  }

  public getTable<T extends {}>(table: string) {
    return new Promise<ITable<T>>((resolve, reject) => {
      try {
        this.getDatabase()
          .then(db => {

            if(!db.objectStoreNames.contains(table))
              throw new Error("Does not exists the table '" + table + "'");

            /**
             * Instance of ITable
             */
            const tableItem: ITable<T> = {
              findAll(condition) {
                return new Promise((resolve, reject) => {
                  try {
                    const transaction = db.transaction([table], 'readonly');

                    const requestCursor = transaction.objectStore(table).openCursor();
                    const resultData: T[] = [];
                    let offset = 0;

                    requestCursor.onsuccess = (ev) => {
                      const cursor = requestCursor.result;

                      if(cursor && (!(condition && condition.limit) || resultData.length < condition.limit)) {
                        if(!( condition && condition.offset) || offset >= condition.offset) {
                          let result = !(condition && condition.where) || Object.keys(condition.where).every(keyName => {
                            return condition.where![keyName as keyof T] == cursor.value[keyName];
                          });

                          if(result) {
                            resultData.push(cursor.value);
                          }
                        }
                        cursor.continue();
                      }
                      else resolve(resultData);
                    }

                    requestCursor.onerror = (ev) => {
                      reject(requestCursor.error);
                    }
                  }
                  catch(err) {
                    reject(err);
                  }
                });
              },
              delete(condition) {
                return new Promise<void>((resolve, reject) => {
                  try {
                    const transaction = db.transaction([table], 'readwrite');

                    const requestCursor = transaction.objectStore(table).openCursor();
                    let offset = 0;

                    requestCursor.onsuccess = (ev) => {
                      const cursor = requestCursor.result;

                      if(cursor) {
                        if(!(condition && condition.offset) || offset >= condition.offset) {
                          let result = !( condition && condition.where) || Object.keys(condition.where).every(keyName => {
                            return condition.where![keyName as keyof T] == cursor.value[keyName];
                          });

                          if(result) {
                            const request = cursor.delete();

                            request.onsuccess = function() {
                              console.log("Registro eliminado");
                            }

                            request.onerror = function() {
                              console.log("Hubo un error al eliminar el registro");
                            }
                          }
                        }
                        cursor.continue();
                      }
                      else resolve();
                    }

                    requestCursor.onerror = (ev) => {
                      reject(requestCursor.error);
                    }
                  }
                  catch(err) {
                    reject(err);
                  }
                });
              },
              findOne(condition) {
                return new Promise((resolve, reject) => {
                  try {
                    const transaction = db.transaction([table], 'readonly');

                    const requestCursor = transaction.objectStore(table).openCursor();
                    let offset = 0;

                    requestCursor.onsuccess = (ev) => {
                      const cursor = requestCursor.result;

                      if(cursor) {
                        if(!(condition && condition.offset) || offset >= condition.offset) {
                          let result = !(condition && condition.where) || Object.keys(condition.where).every(keyName => {
                            return condition.where![keyName as keyof T] == cursor.value[keyName];
                          });

                          if(result) {
                            return resolve(cursor.value);
                          }
                        }
                        cursor.continue();
                      }
                      else resolve(null);
                    }

                    requestCursor.onerror = (ev) => {
                      reject(requestCursor.error);
                    }
                  }
                  catch(err) {
                    reject(err);
                  }
                });
              },
              getName() {
                return table;
              },
              insert(reg: T | T[]) {
                return new Promise<any>((resolve, reject) => {
                  try {
                    const transaction = db.transaction([table], 'readwrite');
                    const objectStorage = transaction.objectStore(table);
                    const adds = reg instanceof Array ? reg : [reg];

                    Promise.all(adds.map(addItem => {
                      return new Promise<T>((resolve, reject) => {
                        try {
                          const request = objectStorage.add(addItem);

                          request.onsuccess = (ev) => {
                            resolve(request.result as unknown as T);
                          }

                          request.onerror = (ev) => {
                            reject(request.error);
                          }
                        }
                        catch(err) {
                          reject(err);
                        }
                      })
                    }))
                      .then((resultAll) => {
                        resolve(resultAll);
                      })
                      .catch(reject);
                  }
                  catch(err) {
                    reject(err);
                  }
                });
              },
              update(newData: {[key in keyof T]?: T[key]}, condition) {
                return new Promise((resolve, reject) => {
                  try {
                    const transaction = db.transaction([table], 'readwrite');

                    const requestCursor = transaction.objectStore(table).openCursor();
                    let offset = 0;

                    requestCursor.onsuccess = (ev) => {
                      const cursor = requestCursor.result;
                      let n_affected = 0;

                      if(cursor) {
                        if(!(condition && condition.offset) || offset >= condition.offset) {
                          let result = !(condition && condition.where) || Object.keys(condition.where).every(keyName => {
                            return condition.where![keyName as keyof T] == cursor.value[keyName];
                          });

                          if(result) {
                            n_affected++;

                            const request = cursor.update({
                              ...cursor.value,
                              ...newData
                            });

                            request.onsuccess = function() {
                              console.log("Registro insertado...");
                            }

                            request.onerror = function() {
                              reject(request.error);
                            }
                          }
                        }
                        cursor.continue();
                      }
                      else resolve({ n_affected });
                    }

                    requestCursor.onerror = (ev) => {
                      reject(requestCursor.error);
                    }
                  }
                  catch(err) {
                    reject(err);
                  }
                });
              }
            }

            resolve(tableItem);
          })
          .catch(reject);
      }
      catch(err) {
        reject(err);
      }
    });
  }


  public async inicializeIndexDB(){
  try {
    const db = await this.inicializeDB("fibextelecom", modelsFibextelecom);
    console.log(db);
    console.log(db.objectStoreNames);
    // this.localStorage.setWithExpiry("categories","categories",ExpirationTime.thirthySeconds);
    //crear un objeto cache con todas las tablas y agregar un tiempo de expiracion para cada una
    
  } catch (error) {
      console.log(error);
  }
  }
  async test(){
      // this.inicializeIndexDB();
  //  const table  = await this.getTable<{id_cantidad: number}>("tm_cantidad");
  //  table.insert({id_cantidad: 1});
  //  table.findAll({where:{id_cantidad: 3}})
  }
}
